import TranslationSheet from './TranslationSheet.svelte';
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import 'jspreadsheet-ce/dist/jexcel.css';
import 'jsuites/dist/jsuites.css';
export default class TranslationSheetHTMLElement extends HTMLElement {
    constructor() {
        super();
        this.translations = {};
        this.component = undefined;
    }
    set translation(tl) {
        this.translations = tl;
    }
    get translation() {
        return this.translations;
    }
    connectedCallback() {
        this.style.width = "100%";
        this.style.height = "100%";
        this.component = new TranslationSheet({
            target: this,
            props: {
                translations: this.translations
            }
        });
        this.component.$on('change', (event) => {
            const data = event.detail;
            this.dispatchEvent(new CustomEvent('change', { detail: data }));
        });
    }
    disconnetedCallback() {
        this.component.$destroy();
    }
}
try {
    customElements.define('translation-sheet', TranslationSheetHTMLElement);
}
catch (e) {
    console.log('define translation-sheet', e);
}
