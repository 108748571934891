<script lang="ts">
  import {
    FASTDesignSystemProvider,
    FASTTextArea,
    FASTAccordion,
    FASTAccordionItem,
    FASTTabs,
    FASTTab
  } from '@microsoft/fast-components';
  import { createEventDispatcher } from 'svelte';
  import TranslationSheet from './TranslationSheet.svelte';
  import WidgetField from './WidgetFields/WidgetField.svelte';
  import { messageIdToExpression, expressionToMessageId } from './WidgetFields/messageIdToExpression';

  const dispatch = createEventDispatcher();

  export let monaco = undefined;
  export let fields: Field[] = [];
  export let translations = {};
  export let messageExpressions = { messageIdToExpression, expressionToMessageId };

  /* To make sure these components are included in final bundle after tree shaking */
  [FASTDesignSystemProvider, FASTTextArea, FASTAccordion, FASTAccordionItem, FASTTabs, FASTTab];

  let expanded = [true];
  let wrapper: HTMLElement;
  let popupElement: HTMLElement;

  function checkExpand(event: PointerEvent) {
    const accordItems = Array.from(wrapper.querySelectorAll('fast-accordion-item'));
    setTimeout(async () => {
      expanded = accordItems.map((elm) => elm['expanded']);
    });
  }

  let choosingMessageId: Field;
  function popupClicked(event) {
    let element = <HTMLElement>event.target;
    if (element.tagName === 'BUTTON') {
      element = element.parentElement;
    }
    if (element.querySelector('button')) {
      const dataY = element.getAttribute('data-y');
      if (dataY) {
        if (choosingMessageId) {
          const messageIdCell = <HTMLElement>popupElement.querySelector(`td[data-x="0"][data-y="${dataY}"]`);
          choosingMessageId['messageId'] = messageIdCell.innerText;
          dispatch('change', fields);
          fields = fields;
        }
        choosingMessageId = undefined;
      }
    }
  }

</script>

<div class="fields">
  <fast-design-system-provider use-defaults bind:this={wrapper} on:click={checkExpand}>
    <fast-accordion expand-mode="single">
      {#each fields as field, index}
        <WidgetField
          {messageExpressions}
          {field}
          {fields}
          {index}
          {monaco}
          {expanded}
          bind:choosingMessageId
          on:change
        />
      {/each}
    </fast-accordion>
  </fast-design-system-provider>
  {#if choosingMessageId}
    <div class="popup" on:click={popupClicked} bind:this={popupElement}>
      <TranslationSheet
        {translations}
        chooseRow
        on:change={(event) => {
          if (JSON.stringify(translations) !== JSON.stringify(event.detail)) {
            translations = event.detail;
            dispatch('change', fields);
          }
        }}
      />
    </div>
  {/if}
</div>

<style>
  .fields {
    --theme-bg: #222;
    --theme-color: #f0f0f0;
    background: var(--theme-bg);
    color: var(--theme-color);
    height: 100%;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    z-index: 10000;
    width: 500px;
    min-width: max-content;
    padding: 0.3rem;
    padding-bottom: 0;
    background: #f5f5f5;
    color: black;
    box-shadow: 0 0 0.5rem #777;
  }

</style>
