<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  export let text = '';
  export let mode = 'inspecting';
  export let offset = new DOMRect();
  export let boundries: DOMRect = null;
  export let disabled = false;
  export let css = '';
  export let icon = 'settings';
  export let contentWindow: Window = window;

  const dispatch = createEventDispatcher();

  let tippyWidth = 0;
  let tippyHeight = 0;
  let windowWidth = 0;
  let windowHeight = 0;
  let mouse = [0, 0];

  let alignX: 'left' | 'center' | 'right' = 'right';

  let lastActiveWindow;
  $: switchContext(contentWindow || window);
  function switchContext(window: Window) {
    if (window !== lastActiveWindow) {
      if (lastActiveWindow) lastActiveWindow.removeEventListener('pointermove', pointerMoved);
      window.addEventListener('pointermove', pointerMoved, { passive: true });
      lastActiveWindow = window;
    }
  }

  function pointerMoved(e: MouseEvent) {
    mouse = [e.clientX, e.clientY];
    if (tippyWidth > boundries.width) {
      alignX = e.x < contentWindow.innerWidth - tippyWidth ? 'left' : 'right';
    } else if (tippyWidth < boundries.width / 3) {
      if (mouse[0] < boundries.left + boundries.width / 3) {
        alignX = 'left';
      } else if (mouse[0] < boundries.left + (boundries.width * 2) / 3) {
        alignX = 'center';
      } else {
        alignX = 'right';
      }
    } else if (tippyWidth < boundries.width / 2) {
      if (mouse[0] < boundries.left + boundries.width / 2) {
        alignX = 'left';
      } else {
        alignX = 'right';
      }
    } else {
      alignX = 'right';
    }
  }

  $: top = offset.top + boundries.top;
  $: bottom = offset.top + boundries.bottom;
  $: otherVars = `
  --tippy-width: ${tippyWidth}px;
  --tippy-height: ${tippyHeight}px;
  --tippy-arrow-width: 1rem;
  --tippy-arrow-height: 0.5rem;
  --screen-width: ${windowWidth}px;
  --screen-height: ${windowHeight}px;
  --mouse-x: ${mouse[0]}px;
  --mouse-y: ${Math.min(windowHeight - 50, Math.floor(mouse[1] / 200) * 200)}px;
  `;
  $: fatElement = top <= 50 && bottom >= window.innerHeight - 50;

</script>

<svelte:window bind:innerWidth={windowWidth} bind:innerHeight={windowHeight} />

<div
  class="tippy-wrapper iteria-ignore fadein transition {alignX}"
  class:bottom={!fatElement && top <= 50}
  class:transform={fatElement}
  style="{css}{otherVars}"
>
  <div class="arrow" />
  <div class="tippy" bind:clientWidth={tippyWidth} bind:clientHeight={tippyHeight}>
    <div class="actions">
      <div class="action">
        <div class="button" on:click={(e) => dispatch('modechange', e)}>
          <span class="material-icons">
            {mode == 'inspecting' ? icon : 'clear'}
          </span>
        </div>
      </div>

      {#if mode == 'editing'}
        <div class="action" class:disabled title={'clone' + (disabled ? ' is disabled' : '')}>
          <div class="button" on:click={(event) => dispatch('clone', { event })}>
            <span class="material-icons clone">content_copy</span>
          </div>
        </div>
        <div class="action" class:disabled title={'delete' + (disabled ? ' is disabled' : '')}>
          <div class="button" on:click={(event) => dispatch('delete', { event })}>
            <span class="">
              <svg focusable="false" width="20" height="20" viewBox="0 0 24 24" class=" NMm5M">
                <path
                  fill="white"
                  d="M15 4V3H9v1H4v2h1v13c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V6h1V4h-5zm2 15H7V6h10v13z"
                /><path fill="white" d="M9 8h2v9H9zm4 0h2v9h-2z" />
              </svg>
            </span>
          </div>
        </div>
        <div class="action" class:disabled title={'source' + (disabled ? ' is disabled' : '')}>
          <div class="button" on:click={(event) => dispatch('showsource', { event })}>
            <span class="material-icons showsource">code</span>
          </div>
        </div>
      {/if}
    </div>
    {#if mode === 'inspecting' && text}
      <span class="text">{text}</span>
    {/if}
  </div>
</div>

<style>
  .tippy-wrapper {
    position: absolute;
    top: calc(var(--offset-top) + var(--position-top) - var(--tippy-height));
    left: max(
      calc(var(--offset-left) + calc(calc(var(--position-right) + var(--position-left) - var(--tippy-width)) / 2)),
      calc(var(--offset-left) + var(--position-right) - var(--tippy-width))
    );
    z-index: 1000;
    transform: translateX(-2px);
    padding-top: 0;
    padding-bottom: var(--tippy-arrow-height);
    font-family: sans-serif;
  }
  /* if css max() not supported (90.69% browsers support this) */
  @supports not (width: max(5px, 5px)) {
    .tippy-wrapper {
      left: calc(var(--offset-left) + var(--position-right) - var(--tippy-width));
    }
  }
  .tippy-wrapper.bottom {
    top: calc(var(--offset-top) + var(--position-bottom) - 3rem);
  }
  .tippy {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333;
    color: white;
    padding: 0 0.2rem;
    width: max-content;
    height: 2rem;
    border-radius: 2px;
    gap: 0.2rem;
  }
  .tippy .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
  }
  .tippy .action {
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
  }
  .tippy .action .button {
    width: 100%;
    height: 100%;
    padding: 0.2rem;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tippy .action:hover {
    background: #ffffff22;
  }
  .tippy .action .material-icons {
    width: 100%;
    height: 100%;
    font-size: 1.1rem;
  }
  .action.disabled {
    opacity: 0.6;
  }
  .action.disabled .button {
    pointer-events: none;
  }
  .tippy-wrapper .arrow {
    position: absolute;
    width: var(--tippy-arrow-width);
    height: var(--tippy-arrow-height);
    background: #333;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    clip-path: polygon(0 0, 100% 0, 50% 100%, 0 0);
    cursor: pointer;
  }
  .tippy-wrapper.left .arrow {
    left: 10%;
    transform: unset;
  }
  .tippy-wrapper.right {
    left: calc(var(--offset-left) + var(--position-right) - var(--tippy-width) + 2px);
  }
  .tippy-wrapper.right .arrow {
    left: 90%;
    transform: translateX(-100%);
  }
  .tippy-wrapper.bottom {
    top: calc(var(--offset-top) + var(--position-bottom) - var(--tippy-arrow-height));
    padding-bottom: 0;
    padding-top: var(--tippy-arrow-height);
  }
  .tippy-wrapper.bottom .arrow {
    top: 0.5px;
    clip-path: polygon(50% 0, 100% 100%, 0 100%, 50% 0);
  }
  .tippy-wrapper.transform {
    top: calc(var(--offset-top) + var(--position-top) + 2px);
  }
  .tippy-wrapper.left {
    transform: translateX(2px);
    left: calc(var(--offset-left) + var(--position-left));
  }
  .tippy-wrapper.center {
    transform: translateX(-50%);
    left: calc(var(--offset-left) + var(--position-left) + calc(var(--position-width) / 2));
  }
  .text {
    min-width: max-content;
    margin-right: 0.2rem;
  }

  .transition {
    transition: left 200ms, top 200ms;
  }

  .fadein {
    animation: fadein 400ms;
  }

  svg {
    margin-top: 4px;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    top {
      opacity: 1;
    }
  }

</style>
