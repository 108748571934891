export function mapTranslationsToSpreadsheet(translations) {
    let _columns = [{ name: 'messageID', title: 'message ID', type: 'text', width: 150 }];
    let _data = [];
    const entries = Object.entries(translations).sort(([a], [b]) => {
        if (a === 'messageID')
            return -3;
        if (a === 'choose')
            return -2;
        if (a === 'en')
            return -1;
        return a < b ? -1 : 1;
    });
    for (const [lang, data] of entries) {
        _columns = [..._columns, { name: lang, title: lang, type: lang === 'choose' ? 'html' : 'text', width: 150 }];
        for (const [messageID, value] of Object.entries(data)) {
            const entry = _data.find((i) => i.messageID === messageID);
            if (entry) {
                entry[lang] = value;
            }
            else {
                _data = [..._data, { messageID, [lang]: value }];
            }
        }
    }
    return {
        columns: _columns,
        data: _data
    };
}
export function mapSpreadsheetToTranslations(instance) {
    const _headers = instance.getHeaders();
    const headers = _headers.split(',');
    let output = {};
    for (const header of headers.slice(1)) {
        output[header] = {};
    }
    for (const row of instance.getData()) {
        const messageID = row[0];
        if (messageID) {
            for (const [index, cellValue] of Object.entries(row.slice(1))) {
                const langCol = headers[parseInt(index) + 1];
                if (langCol) {
                    const langData = output[langCol];
                    if (langData) {
                        langData[messageID] = cellValue;
                    }
                }
            }
        }
    }
    return output;
}
