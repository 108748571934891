<script lang="ts">
  import type { SvelteComponent } from 'svelte';
  import Winbox from 'winbox/src/js/winbox.js';
  import 'winbox/src/css/winbox.css';

  export let component: typeof SvelteComponent = undefined;
  export let props: {[prop: string]: any} = {}
  export let events: {[eventName: string]: (event: any) => void} = {}
  export let title = 'Fields';
  export let x: number | string = 'center';
  export let y: number | string = 'center';
  export let width = 400;
  export let height = 500;
  let target: HTMLElement;

  function newComponent(Component: typeof SvelteComponent, target: Element) {
    const instance = new Component({ target, props });
    const eventNames = Object.keys(events)
    eventNames.forEach(eventName => {
      instance.$on(eventName, events[eventName])
    });
  }

  function winbox(target: HTMLElement, Component: typeof SvelteComponent) {
    const _x = x === 'left' ? 0 : x === 'right' ? window.innerWidth - width : 'center';
    const _y = y === 'top' ? 0 : y === 'bottom' ? window.innerHeight - height : 'center';
    let box = new Winbox({ title, x: _x, y: _y, width, height, root: target, background: 'var(--theme-color)' });
    const winboxBody = target.querySelector(`div.wb-body`);
    if (Component && winboxBody) {
      newComponent(Component, winboxBody);
    }
    return {
      update(Component) {
        if (box && box.close) box.close();
        if (Component) {
          box = new Winbox({
            title,
            x: box.x,
            y: box.y,
            width: box.width,
            height: box.height,
            root: target,
            background: 'var(--theme-color)'
          });
          const winboxBody = target.querySelector(`div.wb-body`);
          if (winboxBody) newComponent(Component, winboxBody);
        }
      },
      destroy() {
        if (box && box.close) {
          box.close();
        }
      }
    };
  }

</script>

<div id="winbox-target" bind:this={target} use:winbox={component}>
</div>

<style>
  #winbox-target {
    z-index: 1000000 !important;
    position: absolute;
  }

</style>
