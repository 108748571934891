<script lang="ts">
  import type { introspection } from './introspection';
  import { FASTDesignSystemProvider, FASTRadioGroup, FASTRadio, FASTButton } from '@microsoft/fast-components';
  import PickField from './PickField.svelte';
  import { createEventDispatcher } from 'svelte';
  [FASTDesignSystemProvider, FASTRadioGroup, FASTRadio, FASTButton];

  export let intro: typeof introspection;
  export let beforeAfter: 'before' | 'after' = 'before';
  export let okButton = false;
  export let mode: 'one' | 'many' = 'one';

  let radio: Element;
  let fields;
  const dispatch = createEventDispatcher();

  function checkByDefault(element: Element) {
    setTimeout(() => {
      element['checked'] = true;
    });
  }

  function notify() {
    const value = radio['value'];
    if (value !== beforeAfter) {
      beforeAfter = value;
      handleChange(new CustomEvent('change', { detail: fields }));
    }
  }

  function handleChange(event: CustomEvent) {
    fields = event.detail;
    dispatch('change', {
      beforeAfter,
      fields: event.detail
    });
  }

  function handleSelect() {
    dispatch('select', {
      beforeAfter,
      fields
    });
  }

  $: isValid = fields && beforeAfter
</script>

<fast-design-system-provider use-defaults>
  <table width="100%">
    <tr>
      <td>
        <fast-radio-group value="before" bind:this={radio}>
          <fast-radio value="before" use:checkByDefault on:click={notify}>Insert before</fast-radio>
          <fast-radio value="after" on:click={notify}>Insert after</fast-radio>
        </fast-radio-group>
      </td>
      <td>
        {#if okButton}
           <fast-button on:click={handleSelect} disabled={!isValid}>OK</fast-button>
        {/if}
      </td>
    </tr>
  </table>
  <PickField {mode} {intro} on:change={handleChange} />
</fast-design-system-provider>

<style>
  fast-design-system-provider {
    height: 100%;
    margin-top: -4px;
    padding-top: 0.5rem;
  }
  fast-radio-group {
    margin-left: 1.5rem;
  }

</style>
