<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import jspreadsheet from 'jspreadsheet-ce';
  import 'jspreadsheet-ce/dist/jspreadsheet.css';
  import 'jspreadsheet-ce/dist/jexcel.css';
  import 'jsuites/dist/jsuites.css';
  import { mapSpreadsheetToTranslations, mapTranslationsToSpreadsheet } from '../helpers/translation';

  export let chooseRow = false;
  export let translations: Translations;

  const dispatch = createEventDispatcher();

  let data = [];
  let columns = [];

  $: {
    const _translations = JSON.parse(JSON.stringify(translations));
    if (chooseRow) {
      const cols = Object.keys(Object.values(translations)[0]);
      _translations['choose'] = Object.fromEntries(cols.map((col) => [col, '<button>choose</button>']));
    }
    const { data: _data, columns: _columns } = mapTranslationsToSpreadsheet(_translations);
    data = _data;
    columns = _columns;
  }

  let instance: JSpreadSheet;

  const eventHanlders = {
    onchange: handleChangeinstance,
    ondeleterow: handleChangeinstance,
    ondeletecolumn: handleChangeinstance,
    onpaste: handleChangeinstance,
    oninsertrow: handleChangeinstance,
    oninsertcolumn: handleChangeinstance,
    onfocus: handleChangeinstance,
    onblur: handleChangeinstance,
    oneditionstart: handleChangeinstance,
    oneditionend: handleChangeinstance
  };

  function initiate(target: HTMLElement, columns: any[]) {
    instance = jspreadsheet(target, {
      data: data,
      columns: [...columns],
      ...eventHanlders
    });
    return {
      update(columns: any[]) {
        instance && instance.destroy();
        instance = jspreadsheet(target, {
          data: data,
          columns: [...columns],
          ...eventHanlders
        });
      }
    };
  }

  function handleChangeinstance() {
    const output: Translations = mapSpreadsheetToTranslations(instance);
    if (chooseRow) {
      delete output['choose'];
    }
    dispatch('change', output);
  }

</script>

<div class="spreadsheet" use:initiate={columns} on:keyup={handleChangeinstance} />
