<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import { checkIframe, checkIgnore, listenScrollEvent } from '../helpers';

  export let element: HTMLElement;

  onMount(() => {
    element.addEventListener('keyup', update);
    return () => {
      element.removeEventListener('keyup', update);
    };
  });

  let overlayVars = '';
  let iframe: HTMLIFrameElement = null;
  let removeScrollEventListeners: Function = () => null;
  let elementBoundries: DOMRect = null;
  let iframeBoundries: DOMRect = null;

  $: if (element) update();

  function update() {
    if (checkIgnore(element)) return;
    iframe = checkIframe(element);
    window['iframe'] = iframe;
    updateBoundries();
    removeScrollEventListeners();
    removeScrollEventListeners = listenScrollEvent(iframe || element, updateBoundries);
  }

  function updateBoundries() {
    elementBoundries = element.getBoundingClientRect();
    if (iframe) {
      iframeBoundries = iframe.getBoundingClientRect();
    } else {
      iframeBoundries = {
        top: 0,
        left: 0,
        x: 0,
        y: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
        width: window.innerWidth,
        height: window.innerHeight
      } as DOMRect;
    }
  }

  onDestroy(() => removeScrollEventListeners());

  const stroke = 2;
  $: if (elementBoundries) {
    overlayVars = `
    --stroke: ${stroke}px;

    --local-top: ${elementBoundries.top - stroke}px;
    --local-right: ${elementBoundries.right + stroke}px;
    --local-bottom: ${elementBoundries.bottom + stroke}px;
    --local-left: ${elementBoundries.left - stroke}px;
    --local-width: ${elementBoundries.width}px;
    --local-height: ${elementBoundries.height - stroke}px;

    --offset-top: ${iframeBoundries ? iframeBoundries.top : 0}px;
    --offset-left: ${iframeBoundries ? iframeBoundries.left : 0}px;
    --offset-right: ${iframeBoundries ? iframeBoundries.right : 0}px;
    --offset-bottom: ${iframeBoundries ? iframeBoundries.bottom : 0}px;
    --offset-width: ${iframeBoundries ? iframeBoundries.width : 0}px;
    --offset-height: ${iframeBoundries ? iframeBoundries.height : 0}px;
    `;
  }

</script>

<!-- Update on resize -->
<svelte:window on:resize={update} />

{#if element}
  <div class="overlays" style={overlayVars}>
    <div class="overlay overlay-1" on:pointerenter on:click />
    <div class="overlay overlay-2" on:pointerenter on:click />
    <div class="overlay overlay-3" on:pointerenter on:click />
    <div class="overlay overlay-4" on:pointerenter on:click />
  </div>
{/if}

<style>
  .overlay {
    position: absolute;
    background: #44444488;
  }
  .overlay-1 {
    top: var(--offset-top);
    left: var(--offset-left);
    width: calc(var(--offset-right) - var(--offset-left));
    height: calc(var(--local-top));
  }
  .overlay-2 {
    top: calc(var(--local-top) + var(--offset-top));
    left: calc(var(--offset-left) + var(--local-right));
    width: calc(var(--offset-right) - var(--local-right));
    height: calc(var(--local-bottom) - var(--local-top));
  }
  .overlay-3 {
    top: calc(var(--offset-top) + var(--local-bottom));
    left: var(--offset-left);
    width: calc(var(--offset-right) - var(--offset-left));
    height: calc(var(--offset-bottom) - var(--offset-top) - var(--local-top) - var(--local-height));
  }
  .overlay-4 {
    top: calc(var(--local-top) + var(--offset-top));
    left: var(--offset-left);
    width: var(--local-left);
    height: calc(var(--local-bottom) - var(--local-top));
  }

</style>
