export function checkIframe(element) {
    if (element.ownerDocument !== document) {
        const iframes = Array.from(document.querySelectorAll('iframe'));
        return iframes.find((iframe) => iframe.contentDocument === element.ownerDocument);
    }
}
export function checkIgnore(element) {
    try {
        if (element.classList.contains('iteria-ignore'))
            return true;
        if (element.tagName === 'BODY')
            return false;
        return checkIgnore(element.parentElement);
    }
    catch (error) {
        return true;
    }
}
export function listenScrollEvent(element, callback) {
    let scrollable = element;
    const scrollableElements = new Set();
    while (scrollable) {
        const { scrollHeight, offsetHeight, scrollWidth, offsetWidth } = scrollable;
        const isScrollable = scrollHeight > offsetHeight || scrollWidth > offsetWidth;
        if (isScrollable && !scrollableElements.has(scrollable)) {
            scrollableElements.add(scrollable);
            scrollable.removeEventListener('scroll', callback);
            scrollable.addEventListener('scroll', callback);
        }
        if (scrollable.tagName === 'BODY')
            break;
        scrollable = scrollable.parentElement;
    }
    return () => {
        scrollableElements.forEach(element => {
            element.removeEventListener("scroll", callback);
        });
    };
}
export function isBlue(element) {
    const rgb = window.getComputedStyle(element, null).backgroundColor;
    if (rgb) {
        const colors = [];
        rgb.replace(/\d+/g, (color) => {
            colors.push(parseInt(color));
            return "";
        });
        if (colors.length >= 3) {
            if (typeof colors[3] !== "undefined") {
                if (colors[3] < 0.5) {
                    if (element.tagName === "BODY")
                        return false;
                    return isBlue(element.parentElement);
                }
            }
            if (colors[0] < colors[2] && colors[1] < colors[2])
                return true;
        }
    }
    return false;
}
